.form-control {
    .mat-mdc-form-field,
    mat-form-field {
        width: 100%;
    }
    margin-bottom: 1rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.error-message {
    color: #f44336;
}
