$breakpoints: (
  'phone': 576px,
  'tablet': 768px,
  'desktop': 1025px,
  'desktop-large': 1200px
);

@mixin breakpoint-up($breakpoint) {
  @if map-has-key($breakpoints , $breakpoint ) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  @else {
    @warn 'Breakpoint `#{$breakpoint}` can\'t be found. ' +
    'Available breakpoints are: ${map-keys($breakpoints)}.';
  }
}

@mixin breakpoint-down($breakpoint) {
  @if map-has-key($breakpoints , $breakpoint ) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  @else {
    @warn 'Breakpoint `#{$breakpoint}` can\'t be found. ' +
    'Available breakpoints are: ${map-keys($breakpoints)}.';
  }
}