@import "responsive.mixin";

.info-form {
  display: flex;
  flex-direction: column;
  @include breakpoint-up("desktop") {
    flex-direction: row;
  }
  .column {
    flex: 1;
    @include breakpoint-up("desktop") {
      &:first-child {
        margin-right: 3.5rem;
      }
    }
    .form-card {
      .card-title {
        font-weight: 700;
      }
      mat-form-field {
        margin-bottom: 1rem;
      }
      .btn-company-submit {
        align-self: flex-start;
      }
    }
  }

  .form-card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 2rem 0;
    @include breakpoint-up("tablet") {
      padding: 2rem;
    }
    @include breakpoint-up("desktop") {
      padding: 3.5rem;
    }
  }


  .image-preview {
    margin-bottom: 2rem;
    input[type="file"] {
      display: none;
    }
    .logo {
      display: block;
      max-width: 100%;
      height: auto;
    }
    .placeholder {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 1rem;
      background-color: lightgray;
      min-height: 5rem;
    }
    .controls {
      padding-top: 1rem;
      button:first-child {
        margin-right: 1rem;
      }
    }
  }

  .form-group {
    &.color-picker-wrapper {
      position: relative;
      margin-bottom: 1rem;
      max-width: 15rem;
    }
  }

  .error {
    margin-top: -23px;
    font-size: 12px;
    color: red;
  }

  .selected-companies {
    padding-left: 2rem;
    &-header {
      margin: 1rem 0;
    }
    &-list {
      padding-left: 0;
      list-style-type: none;
      margin: 0;
    }
    &-item {
        padding-top: 1rem;
    }
  }
}


