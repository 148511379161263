@import 'src/scss/responsive.mixin.scss';

.kpi-statistics-wrapper {
  max-width: 90%;
  margin: 2rem auto;
  @include breakpoint-up("desktop") {
    max-width: 88rem;
  }
  .back-button-wrapper {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  form {
    width: 100%;
    padding: 0 1rem;
  }
  .kpi-charts-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-top: 2.5rem;
  }
  .help-wrapper {
    display: flex;
  }

  .row {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 2.5rem;
    &.half-width {
      @include breakpoint-up("desktop") {
        width: 50%;
      }
    }
  }
}
