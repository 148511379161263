@import 'responsive.mixin';

.two-card-wrapper {
  display: flex;
  flex-direction: column;
  @include breakpoint-up('desktop') {
    flex-direction: row;
  }
    .form-card {
      flex: 1;
      @include breakpoint-up('desktop') {
        &:first-child {
          margin-right: 3.5rem;
        }
      }
      display: flex;
      flex-direction: column;
      margin: 2rem 0;
      padding: 1rem;
      @include breakpoint-up('tablet') {
        padding: 2rem;
      }
      @include breakpoint-up('desktop') {
        padding: 3.5rem;
      }
      .card-title {
        font-weight: 700;
      }
      .button-wrapper {
        display: flex;
        justify-content: flex-end;
      }
      mat-form-field {
        margin-bottom: 1rem;
      }
    }
}
